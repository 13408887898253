.modal {
    $root: #{&};
    $active: #{$root}--active;
    $left: #{$root}--left;
    $right: #{$root}--right;
    $top: #{$root}--top;
    $bottom: #{$root}--bottom;
    $levels-active: #{$root}--levels-active;

    position: fixed;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: 3;
    max-height: calc(100vh / 2); // additional division by 2.5 is due to the fact it is scaled to this value
    max-width: calc((100vw / 3 - 185px));

    &--active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        top: 50%;
        transform-origin: top left;
        
        @at-root #{$right}#{&} {
            left: 50%;
            transform: translate(140px, -100px);
        }
        @at-root #{$left}#{&} {
            right: 50%;
            transform: translate(calc((-150% - 100px)), -100px);
            @at-root #{$levels-active}#{&} {
                transform: translate(calc((-43% - 100px)), -100px);
            }
        }
    }

    &__line {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        pointer-events: none;
        z-index: -1;

        #{$right} & {
            left: -100%;
            right: 100%;
        }

        #{$left} & {
            left: 100%;
            right: -100%;
        }

        &:before,
        &:after {
            content: "";
            top: 0;
            width: 0;
            position: absolute;
            transition: all $a-short;
        }

        &:before {
            height: 1px;
            background: $white;
            z-index: 2;

            #{$right} & {
                left: calc((100% + #{($link-row-height * 0.375)}));
            }
            
            #{$left} & {
                right: calc(100% + #{($link-row-height * 0.375)});
            }

            #{$active} & {
                transition-delay: $a-short * 2;
                width: calc(100% - #{$link-row-height});
            }
        }

        &:after {
            border: 0;
            border-top-width: 1px;
            border-style: solid;
            border-color: $white;
            z-index: 1;
            height: ($link-row-height * 2.5 * $scale);
            width: 0;
            
            #{$right} & {
                transform-origin: top right;
                transform: translate(12px * $scale, 0) rotate(-30deg);
                right: 0;
            }
            
            #{$left} & {
                transform-origin: top left;
                transform: translate(56px * $scale, 40px * $scale) rotate(30deg);
                left: 0;
            }

            #{$active} & {
                transition-delay: $a-short * 1;
                width: ($link-row-height * 2.5);

                @at-root #{$left}#{&} {
                    transform: translate(-12px * $scale, 0) rotate(30deg);
                }
            }
        }

        &-dot {
            width: 5px * $scale;
            height: 5px * $scale;
            display: block;
            background-color: white;
            border-radius: 50%;
            position: absolute;

            #{$right} & {
                top: 38px * $scale;
                right: 55px * $scale;
            }

            #{$left} & {
                top: 38px * $scale;
                left: 55px * $scale;
            }
        }
    }

    &__flare {
        height: 20px * $scale;
        width: 20px * $scale;
        display: block;
        background: radial-gradient(
            circle,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 50%
        );
        border-radius: 50%;
        position: absolute;
        animation-duration: $a-short * 5;
        z-index: 4;
        opacity: 1;
        transform-origin: center center;
        top: 0;
        opacity: 0;
        pointer-events: none;
        
        #{$left} & {
            right: 0;
        }
        
        #{$right} & {
            left: 0;
        }

        #{$active} & {
            animation-delay: $a-short * 1.5;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            
            @at-root #{$right}#{&} {
                animation-name: flare-ltr;
            }

            @at-root #{$left}#{&} {
                animation-name: flare-rtl;
            }
        }
    }

    &__close {
        cursor: pointer;
        appearance: none;
        border: 0;
        opacity: 0;
        height: $link-row-height * 0.625;
        width: $link-row-height * 0.625;
        display: block;
        position: absolute;
        border-radius: 50%;
        background: $purple;
        border: 0.1px solid white;

        #{$left} & {
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }

        #{$right} & {
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
        }

        #{$active} & {
            transition: $a-short;
            transition-delay: $a-short * 3.5;
            opacity: 1;
        }

        &:before,
        &:after {
            content: "";
            height: $link-row-height * 0.0625;
            width: $link-row-height * 0.375;
            background: $white;
            text-align: center;
            pointer-events: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        
        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__levels {
        display: grid;
        grid-template-areas: 'levels links';
        
        #{$left} & {
            grid-template-areas: 'links levels';
        }
        // height: 0;

        > ul {
            @include list-style-reset;
            @include hide-scrollbar;
            grid-area: levels;
            align-self: start;
            border-radius: $link-row-height/2;
            border: 1px solid $blue--light;
            background: $white;
            overflow: hidden;
            border-radius: $link-row-height/2;
            padding: $link-row-height * 0.2 0;
            height: 0;
            padding: 0;
            opacity: 0;

            #{$active} & {
                height: unset;
                padding: $link-row-height * 0.2 0;
                opacity: 1;
                transition: all $a-short;
                transition-delay: $a-short * 3.5;
            }
        }
    }

    &__level {
        cursor: pointer;
        line-height: $link-row-height * 0.2;

        &:hover {
            background: #eee;
        }

        span {
            color: black;
            text-decoration: none;
            font-family: $font;
            font-size: $link-row-font-size * 0.375;
            padding: $link-row-height * 0.1 #{6px*$scale};
            display:block;
            pointer-events: none;
            text-align: center;
        }
    }

    &__links {
        overflow:hidden;
        border-radius: $link-row-height/2;

        #{$root}__levels & {
            grid-area: links;
            align-self: start;
            display: none;

            &--active {
                display: block;

                ul {
                    height: unset;
                }
            }
        }

        ul {
            @include list-style-reset;
            @include hide-scrollbar;
            border-radius: $link-row-height/2;
            border: 1px solid $blue--light;
            background: $white;
            height: 0;
            overflow: hidden;
            padding: $link-row-height * 0.2 0;
            transition: all $a-short;
            transition-delay: $a-short * 3.5;

            #{$active} > & {
                height: 100%;
                overflow:auto;
            }

            #{$root}__level & {
                transition: all $a-short;
                height: 0!important;
                transition-delay: 0s;
                padding-top: 0;
                padding-bottom: 0;
                border: 0;
            }

            #{$root}__level:hover & {
                height: 100%!important;
                border: 1px solid $blue--light;
                padding: $link-row-height * 0.2 0;
            }
        }
    }

    &__link {
        flex: 0 0 100%;
        text-align: center;
        line-height: $link-row-height * 0.2;
        max-width: calc(100vw / 3 / 2.5); // additional division by 2.5 is due to the fact it is scaled to this value

        &:hover {
            background: #eee;
        }

        a {
            color: black;
            text-decoration: none;
            font-family: $font;
            font-size: $link-row-font-size * 0.375;
            padding: $link-row-height * 0.1 6px;
            display:block;
        }
    }
}

@keyframes flare-ltr {
    0% {
        transform: translate(-70px * $scale, 30px * $scale);
        opacity: 1;
    }
    25% {
        transform: translate(2px * $scale, -10px * $scale);
        opacity: 1;
        left: 0;
    }
    50% {
        left: 100%;
        transform: translate(2px * $scale, -10px * $scale);
        opacity: 0;
    }
    75% {
    }
}

@keyframes flare-rtl {
    0% {
        transform: translate(67px * $scale, 30px * $scale);
        opacity: 1;
    }
    25% {
        transform: translate(-3px * $scale, -10px * $scale);
        opacity: 1;
        right: 0;
    }
    50% {
        right: 100%;
        transform: translate(-3px * $scale, -10px * $scale);
        opacity: 0;
    }
    75% {
    }
}
