.graphic {
    $root: #{&};
    $hover: #{$root + ':hover'};
    $zoomed: #{$root}--zoomed;

    background-image: url("/src/assets/background.jpg");
    background-position: center;
    background-size: cover;
    display: block;
    height: 100vh;
    width: 100vw;
    color: $white;
    position: relative;
    overflow: hidden;

    &__container {
    }

    &__header {
        position: absolute;
        top: 64px;
        padding-left: 80px;
        left: 0;
        right: 0;
        margin:auto;
        z-index:2;
        max-width: $container;
        width: 100%;
        pointer-events: none;
    }

    &__text {
        transform-origin: top left;
        transform: scale(1);
        transition: all $a-short;

        #{$zoomed} & {
            transform: scale(0.5);
        }
    }

    &__title {
        $font-size: clamp(50px, 5vmin, 75px);
        font-size: $font-size;
        line-height: $font-size;
        margin: 0 0 40px;
        font-family: $font;
        pointer-events: none;

        span {
            @include text-bg-gradient(90deg, $pink, $blue--light);
            display: block;
        }
    }

    &__subtitle {
        font-size: clamp(20px, 3vmin, 28px);
        line-height: clamp(24px, 3vmin, 32px);
        margin: 0 0 40px;
        font-family: $font;
        pointer-events: none;
    }

    &__graphic {
        z-index:1;
        max-width: $container;
        margin: 0 auto;
        overflow: unset!important;
        padding: 50px;

        * {
            &:focus {
                outline: 0;
            }
        }
    }

    &__scene {
        @include hide-scrollbar;
        
        transition: transform $a-short;
        width: auto;
        padding: 0 $gutter 0;
        // position: relative;

        svg {
            height: calc(100vh - 100px);
            max-width: 100%;
            margin: 0 auto;
            display: block;

            image {
                pointer-events: none;
            }
        }

        a {
            cursor: pointer;
            transition: all $a-short;

            &:hover {
                filter: drop-shadow(0 0 4px white);
            }

            &.no-modal {
                .cls-35, .cls-29 {
                    fill: #ddd;
                }
            }
        }
    }

    &__controls {
        display: flex;
        flex-direction: column;
        gap: 15px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        width: 50px;

        #{$hover} & {
            pointer-events: all;
            opacity: 1;
        }
    }

    &__control {
        background: white;
        appearance: none;
        border-radius: 50%;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 26px;
        font-weight: 700;
        border: 0;
        cursor: pointer;
        box-shadow: 0 0 4px 0 white;

        &--zoom-in {
        }

        &--zoom-out {
        }
    }
}
