// Solours
$white: #fff;
$pink: #ef7ff9;
$blue: 0;
$blue--light: #3aa2f3;
$purple: #6400b8;
// Container
$container: 1500px;
$gutter: 32px;
$font: "BT Curve",Calibri,sans-serif;
$scale: 2.5;

// Animation
$a-short: .3s;

$link-row-height: 32px * $scale;
$link-row-font-size: 16px * $scale;