@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.eot');
    src: url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.woff2') format('woff2'),
        url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.woff') format('woff'),
        url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.ttf') format('truetype'),
        url('../fonts/BT Curve/3c725e8c24ab65a741fcb99b38b2aae9.svg#.') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.eot');
    src: url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.woff2') format('woff2'),
        url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.woff') format('woff'),
        url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.ttf') format('truetype'),
        url('../fonts/BT Curve/6b52314fb773413025b3cd289da6f125.svg#.') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.eot');
    src: url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.woff2') format('woff2'),
        url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.woff') format('woff'),
        url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.ttf') format('truetype'),
        url('../fonts/BT Curve/73d162eebe4de53dccac345d84dd8636.svg#.') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.eot');
    src: url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.woff2') format('woff2'),
        url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.woff') format('woff'),
        url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.ttf') format('truetype'),
        url('../fonts/BT Curve/ef4c9de7f762fe31836e01eec7465a52.svg#.') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.eot');
    src: url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.woff2') format('woff2'),
        url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.woff') format('woff'),
        url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.ttf') format('truetype'),
        url('../fonts/BT Curve/b0319671347de3cbfb348a681417d90b.svg#.') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BTCurve';
    src: url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.eot');
    src: url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.woff2') format('woff2'),
        url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.woff') format('woff'),
        url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.ttf') format('truetype'),
        url('../fonts/BT Curve/59a9f688382327b9cdc559550acdd91b.svg#.') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

