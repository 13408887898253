@mixin text-bg-gradient($dir: 0deg, $from: #000, $to: #fff) {
    background: linear-gradient(
        $dir,
        rgba($from, 1) 0%,
        rgba($to, 1) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
